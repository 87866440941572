import Menu from "@mui/icons-material/Menu";
import { Badge, Box, Button, IconButton } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import {
  Collapse,
  Dialog,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "./../assets/image/LOGO 4.3.png";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";
import publicar from "./../assets/image/publicar.jpg";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();


  const carrito = useSelector((state) => state.carrito?.carro ?? []);


  return (
    <Paper elevation={10}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{
          width: "96%",
          margin: "0% 2% 0% 2%",
          // background: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Box
          padding={"10px"}
          sx={{ display: "flex", alignItems: "center", gap: 3 }}
        >
          <Button
            variant="plain"
            size="lg"
            onClick={() => navigate("/")}
            component={Link}
            to={"/"}
          >
            <img alt="logo" src={logo} width={"100px"} />
          </Button>
          <Button
            variant="solid"
            size="md"
            component={Link}
            to={"https://clientes.publimed.net"}
          >
            <Typography
              fontWeight={"bold"}
              sx={{ fontSize: { xs: "15px", sm: "20px" } }}
              textAlign={"center"}
            >
              Portal de Clientes
            </Typography>
          </Button>
        </Box>
        <Box padding={"10px"} display={{ xs: "none", sm: "none", md: "flex" }}>
          <Button
            variant="plain"
            size="lg"
            onClick={() => navigate("/tienda")}
            component={Link}
            to={"/tienda"}
          >
            <Typography fontWeight={"bold"} variant="h6">
              Tienda
            </Typography>
          </Button>
          <Button
            variant="plain"
            size="lg"
            onClick={() => navigate("/carrito")}
            component={Link}
            to={"/carrito"}
          >
            <Typography fontWeight={"bold"} variant="h6" pr={1}>
              Carrito
            </Typography>
            <Badge
              badgeContent={carrito?.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  parseFloat(
                    parseFloat(currentValue?.cantidad ?? 0).toFixed(2)
                  ),
                0
              )}
              color="danger"
            >
              <ShoppingCartIcon />
            </Badge>
          </Button>
          <Button
            component={Link}
            to={"/nosotros"}
            variant="plain"
            size="lg"
            onClick={() => navigate("/nosotros")}
          >
            <Typography fontWeight={"bold"} variant="h6">
              Nosotros
            </Typography>
          </Button>
        </Box>
        <Box padding={"10px"} display={{ xs: "flex", sm: "flex", md: "none" }}>
          <IconButton
            onClick={() => {
              setOpen(true);
            }}
          >
            <Menu sx={{ fontSize: "36px" }} />
          </IconButton>
        </Box>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 30000 }}
      >
        <DialogContent>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ width: "100%", textAlign: "right" }}>
              <IconButton
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <CloseIcon sx={{ fontSize: "36px" }} />
              </IconButton>
            </Box>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Button
                variant="plain"
                size="lg"
                onClick={() => {
                  setOpen(false);
                  navigate("/tienda");
                }}
              >
                <Typography fontWeight={"bold"} variant="h6">
                  Tienda
                </Typography>
              </Button>
              <br />
              <Button
                variant="plain"
                size="lg"
                onClick={() => {
                  setOpen(false);
                  navigate("/carrito");
                }}
              >
                <Typography fontWeight={"bold"} variant="h6">
                  Carrito
                </Typography>
              </Button>
              <br />
              <Button
                variant="plain"
                size="lg"
                onClick={() => {
                  setOpen(false);
                  navigate("/nosotros");
                }}
              >
                <Typography fontWeight={"bold"} variant="h6">
                  Nosotros
                </Typography>
              </Button>
              <br />
              <Button
                variant="outlined"
                size="lg"
                component={Link}
                to={"https://clientes.publimed.net"}
              >
                <Typography fontWeight={"bold"} variant="h6">
                  Portal de Clientes
                </Typography>
              </Button>
            </Box>
          </Collapse>
        </DialogContent>
      </Dialog>
      {/*
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          position: "fixed",
          left: 0,
          top: 150,
          zIndex: 20000,
        }}
      >
        <Collapse orientation="horizontal" in={anuncio}>
          <Badge
            badgeContent={
              <Box
                sx={{ zIndex: 20001, padding: "5px" }}
                onClick={() => {
                  setAnuncio(false);
                  localStorage.setItem("anuncio", "NO");
                  localStorage.setItem("savedTime", new Date().getTime());
                }}
              >
                <strong>{"X"}</strong>
              </Box>
            }
            color="danger"
          >
            <Box
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=593993490200&text=Hola%2C%20deseo%20publicar%20sobre..."
                )
              }
            >
              <img alt="publicar" src={publicar} width={"500px"} />
            </Box>
          </Badge>
        </Collapse>
         {!anuncio && (
          <Box
            onClick={() => setAnuncio(true)}
            sx={{
              height: "100px",
              border: 1,
              borderRadius: "0px 10px 10px 0px",
              background: theme2.palette.primary.main,
              display: "flex",
              padding: "5px",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"bold"}> {">>"}</Typography>
          </Box>
        )} 
      </Box>

      <Box
        sx={{
          display: { sm: "none", xs: "flex" },
          position: "fixed",
          left: 0,
          top: 150,
          zIndex: 20000,
        }}
      >
        <Collapse orientation="horizontal" in={anuncio}>
          <Badge
            badgeContent={
              <Box
                sx={{ zIndex: 20001, padding: "5px" }}
                onClick={() => {
                  setAnuncio(false);
                  localStorage.setItem("anuncio", "NO");
                  localStorage.setItem("savedTime", new Date().getTime());
                }}
              >
                <strong>{"X"}</strong>
              </Box>
            }
            color="danger"
          >
            <Box
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=593993490200&text=Hola%2C%20deseo%20publicar%20sobre..."
                )
              }
            >
              <img alt="publicar" src={publicar} width={"300px"} />
            </Box>
          </Badge>
        </Collapse>
        {!anuncio && (
          <Box
            onClick={() => setAnuncio(true)}
            sx={{
              height: "100px",
              border: 1,
              borderRadius: "0px 10px 10px 0px",
              background: theme2.palette.primary.main,
              display: "flex",
              padding: "5px",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"bold"}> {">>"}</Typography>
          </Box>
        )}
      </Box>*/}
    </Paper>
  );
};
