import { Box } from "@mui/joy";
import { Dialog, DialogContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Cargando } from "../utils/Cargando";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

export const Resultado = () => {
  const { id } = useParams();
  const [objeto, setObjeto] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    cargar(id);
  }, [id]);

  function cargar(id) {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://portal.publimed.net/api/v1/CCompra/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setObjeto(res);
        
        if (res?.estado === "APROBADO") {
          localStorage.setItem("carritoPublimed", JSON.stringify([]));
          localStorage.setItem("carritoPro", JSON.stringify([]));

          dispatch({
            type: "CARRO_SUCCESS",
            payload: {
              carro: [],
            },
          });
        }
        Swal.fire({
          icon: res?.estado === "APROBADO" ? "success" : "error",
          html: "La compra ha sido: <strong>"+(res?.estado === "APROBADO" ? "APROBADA" : "CANCELADA")+"</strong>",
          willClose: () => {
            navigate("/");
          }
        });
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
        setObjeto({ error: "No Existe" });
      });
  }
  return (
    <Box>
      {/* <Dialog
      open={true}
      maxWidth="md"
      onClose={() => {
        navigate("/");
      }}
    >
       <DialogContent>
        <Box>
          {objeto?.id ? (
            <Typography variant={"h4"} align="center">
              <strong>
                La compra ha sido:{" "}
                <br/>
                {objeto?.estado === "APROBADO" ? "APROBADA" : "CANCELADA"}
              </strong>
            </Typography>
          ) : (
            <Cargando open={!objeto?.id} />
          )}
        </Box>
      </DialogContent>
    </Dialog> */}
    </Box>
  );
};
