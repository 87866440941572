const initialState = {
    info: {
    }
}

export const carroReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CARRO_SUCCESS':
            return { loading: false, ...action.payload }
        case 'CARRO_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}