import { extendTheme } from "@mui/joy/styles";
import { extendTheme as materialExtendTheme } from "@mui/material/styles";

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {},
    },
    dark: {
      palette: {},
    },
  },
});

export const theme2 = materialExtendTheme({
  palette: {
    primary: {
      main: "#0B6BCB",
    },
    secondary: {
      main: "#0B6BCB",
    },
  },
});
