import { Box, Breadcrumbs, Button, Chip, Divider, Link, Stack } from "@mui/joy";
import {
  Autocomplete,
  Grid2,
  MenuItem,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { centrado } from "../utils/Estilos";
import { theme2 } from "../theme";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Cargando } from "../utils/Cargando";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

export const Detalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [objeto, setObjeto] = useState({});

  const [imagen, setImagen] = useState(null);

  const [especialidades, setEspecialidades] = useState([]);

  const dispatch = useDispatch();
  const carrito = useSelector((state) => state.carrito?.carro ?? []);

  useEffect(() => {
    cargar();
    cargar2();
  }, []);

  function formatear(listaD) {
    let grupos = listaD.reduce((acumulador, el) => {
      const clave = `${el?.producto?.id}_${el?.especialidad}_${el?.tema}_${el?.especialidad}_${el?.tiempo}_${el?.eleccion}`; // Crear una clave única por nombre y precio
      if (!acumulador[clave]) {
        acumulador[clave] = {
          ...el,
          cantidad: 0,
        };
      }
      acumulador[clave].cantidad += el?.cantidad;
      return acumulador;
    }, {});

    let final = [];

    for (const el of Object.keys(grupos)) {
      let val = grupos[el];
      let precio = !isNaN(val?.eleccion)
        ? [
            val?.producto?.precio1,
            val?.producto?.precio2,
            val?.producto?.precio3,
          ][val?.eleccion]
        : val?.producto?.precio;

      precio = parseFloat(precio).toFixed(2);
      final.push({
        ...val,
        precioFinal: precio,
        total: (parseFloat(precio) * val?.cantidad).toFixed(2),
      });
    }
    dispatch({
      type: "CARRO_SUCCESS",
      payload: {
        carro: final,
      },
    });
    localStorage.setItem("carritoPublimed", JSON.stringify(final));
    setObjeto({
      producto: objeto?.producto,
    });
    Swal.fire({
      icon: "success",
      text: "Producto añadido al carrito",
    });
  }

  function cargar2() {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://portal.publimed.net/api/v1/Metadata", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setEspecialidades(
          res?.entityDefs?.CProducto?.fields?.especialidades?.options ?? []
        );
      })
      .catch((error) => console.error(error));
  }

  function cargarImagen(imagenid) {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://portal.publimed.net/api/v1/Attachment/file/" + imagenid,
      requestOptions
    )
      .then((response) => response.blob())
      .then((result) => {
        setImagen(URL.createObjectURL(result));
      })
      .catch((error) => console.error(error));
  }

  function cargar() {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://portal.publimed.net/api/v1/CProducto/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        if (res?.fotoId) {
          cargarImagen(res?.fotoId);
        }
        let precios = [];
        if (!isNaN(res?.precio1) && res?.stock1 > 0) {
          precios.push(parseFloat(res?.precio1));
        }
        if (!isNaN(res?.precio2) && res?.stock2 > 0) {
          precios.push(parseFloat(res?.precio2));
        }
        if (!isNaN(res?.precio3) && res?.stock3 > 0) {
          precios.push(parseFloat(res?.precio3));
        }

        if (!isNaN(res?.precio) && res?.tipo === "SIN STOCK") {
          precios.push(parseFloat(res?.precio));
        }

        let imagen = null;
        if (res?.especialidades && res?.especialidades[0]) {
          imagen = res?.especialidades[0]?.replaceAll("í", "б") + ".png";
        }

        if (res?.categorias?.includes("PONENCIA")) {
          imagen = "ponencia.jpg";
        }
        if (res?.categorias?.includes("CONGRESO")) {
          imagen = "congreso.jpg";
        }
        if (res?.categorias?.includes("PREGRADO")) {
          imagen = "pregrado.jpg";
        }
        if (res?.categorias?.includes("POSGRADO")) {
          imagen = "posgrado.jpg";
        }
        if (res?.categorias?.includes("ANTEPRO")) {
          imagen = "ante.jpg";
        }
        if (res?.categorias?.includes("INVESTIG")) {
          imagen = "investigacion.jpg";
        }
        setObjeto({
          producto: {
            ...res,
            imagen: imagen,
            minimo: Math.min(...precios),
            maximo: Math.max(...precios),
          },
        });
      })
      .catch((error) => {
        console.error(error);
        navigate("/tienda");
      });
  }

  return (
    <Box py={3} sx={{ width: "100%", ...centrado, flexWrap: "wrap" }}>
      <Cargando open={!objeto?.producto?.name} />
      {objeto?.producto?.name && (
        <>
          <Box sx={{ width: "90%" }}>
            <Breadcrumbs sx={{ fontSize: "24px" }}>
              <Link
                underline="always"
                color="primary"
                onClick={() => navigate("/tienda")}
              >
                <strong>Tienda</strong>
              </Link>
              <Typography sx={{ color: "text.primary", fontSize: "24px" }}>
                <strong> {objeto?.producto?.name}</strong>
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box sx={{ width: "90%" }}>
            <Grid2 container sx={{ width: "100%", ...centrado }}>
              <Grid2
                size={{ xs: 12, md: 4 }}
                sx={{ padding: "20px", border: 1 }}
              >
                {objeto?.producto?.imagen && !imagen && (
                  <img
                    alt={"fotoProducto"}
                    src={
                      "https://publimed.net/iconos/" + objeto?.producto?.imagen
                    }
                    width={"100%"}
                  />
                )}

                {imagen && (
                  <img
                    alt={"fotoProducto"}
                    src={imagen ?? "https:/publimed.net/iconos/no.png"}
                    width={"100%"}
                  />
                )}
                {/* <img
                  width={"100%"}
                  alt={objeto?.producto?.name}
                  src={
                    imagen ??
                    "https://w7.pngwing.com/pngs/998/203/png-transparent-black-and-white-no-to-camera-logo-video-on-demand-retail-website-simple-no-miscellaneous-television-text.png"
                  }
                /> */}
              </Grid2>
              <Grid2 size={{ xs: 12, md: 8 }} sx={{ padding: "20px" }}>
                <Paper
                  elevation={2}
                  sx={{
                    padding: "20px",
                    border: 1,
                    borderRadius: "25px",
                    minHeight: "400px",
                    borderColor: theme2.palette.primary.main,
                  }}
                >
                  <Typography fontWeight={"bold"} variant="h5">
                    {objeto?.producto?.name}
                  </Typography>
                  <Divider />
                  <br />
                  {objeto?.producto?.especialidades?.length > 0 && (
                    <>
                      <Typography variant="h6">
                        <strong>Especialidades:</strong>{" "}
                        {objeto?.producto?.especialidades?.toString()}
                      </Typography>
                      <br />
                    </>
                  )}
                  <Typography fontWeight={"bold"} variant="h6">
                    Descripción
                  </Typography>
                  <Typography variant="h6" sx={{ whiteSpace: "break-spaces" }}>
                    {objeto?.producto?.description}
                  </Typography>
                  <Typography fontWeight={"bold"} variant="h6" pt={2}>
                    Precio
                  </Typography>

                  {objeto?.producto?.descuento === "SI" && (
                    <Chip
                      color="neutral"
                      size="lg"
                      sx={{ textDecoration: "line-through", padding: "5px" }}
                    >
                      {"$ " +
                        parseFloat(
                          objeto?.producto?.precioSinDescuento
                        ).toFixed(2)}
                    </Chip>
                  )}
                  {objeto?.producto?.tipo === "SIN STOCK" ? (
                    <Chip color="success" size="lg" sx={{ padding: "5px" }}>
                      {"$ " + parseFloat(objeto?.producto?.precio).toFixed(2)}
                    </Chip>
                  ) : (
                    <Chip color="success" size="lg" sx={{ padding: "5px" }}>
                      {"$ " + parseFloat(objeto?.producto?.minimo).toFixed(2)}
                      {objeto?.producto?.minimo !==
                        objeto?.producto?.maximo && (
                        <>
                          {" ~ $ " +
                            parseFloat(objeto?.producto?.maximo).toFixed(2)}
                        </>
                      )}
                    </Chip>
                  )}
                  <br />
                  <br />
                  <Divider />
                  <br />
                  {objeto?.producto?.tipo === "SIN STOCK" ? (
                    <>
                      {objeto?.producto?.solicitar?.length > 0 && (
                        <Typography
                          fontWeight={"bold"}
                          color="primary"
                          variant="h6"
                        >
                          Información Solicitada
                        </Typography>
                      )}

                      {objeto?.producto?.solicitar?.includes(
                        "ESPECIALIDAD"
                      ) && (
                        <Box py={1}>
                          <Typography fontWeight={"bold"} variant="h6">
                            Especialidad:
                          </Typography>
                          <Autocomplete
                            autoHighlight={true}
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            disablePortal
                            slotProps={{
                              listbox: { style: { maxHeight: 200 } },
                            }}
                            value={objeto?.especialidad ?? null}
                            options={especialidades ?? []}
                            getOptionLabel={(option) => option}
                            onChange={(e, t) => {
                              setObjeto({ ...objeto, especialidad: t });
                            }}
                            renderInput={(params) => (
                              <TextField autoComplete="off" {...params} />
                            )}
                          />
                        </Box>
                      )}
                      {objeto?.producto?.solicitar?.includes("TEMA") && (
                        <Box py={1}>
                          <Typography fontWeight={"bold"} variant="h6">
                            Tema:
                          </Typography>
                          <TextField
                            autoComplete="off"
                            size="small"
                            fullWidth
                            value={objeto?.tema ?? ""}
                            onChange={(e) => {
                              setObjeto({ ...objeto, tema: e.target.value });
                            }}
                          />
                        </Box>
                      )}
                      {objeto?.producto?.solicitar?.includes(
                        "TIEMPO ENTREGA"
                      ) && (
                        <Box py={1}>
                          <Typography fontWeight={"bold"} variant="h6">
                            Tiempo de entrega estimado:
                          </Typography>
                          <TextField
                            size="small"
                            autoComplete="off"
                            fullWidth
                            value={objeto?.tiempo ?? ""}
                            onChange={(e) => {
                              setObjeto({ ...objeto, tiempo: e.target.value });
                            }}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        fontWeight={"bold"}
                        variant="h6"
                        color="primary"
                      >
                        Tipos disponibles
                      </Typography>
                      <Typography>
                        Solo se permite tener un producto por cada tipo
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          ...centrado,
                          justifyContent: "left",
                        }}
                      >
                        <ToggleButtonGroup
                          value={objeto?.eleccion ?? ""}
                          exclusive
                          color="primary"
                          onChange={(e, n) =>
                            setObjeto({
                              ...objeto,
                              eleccion: n,
                            })
                          }
                        >
                          {objeto?.producto?.tipo1 && (
                            <ToggleButton
                              value={0}
                              disabled={objeto?.producto?.stock1 < 1}
                            >
                              <Stack sx={{ padding: "0px 20px 0px 20px" }}>
                                <Typography>
                                  {objeto?.producto?.tipo1}
                                </Typography>
                                <Typography>
                                  <strong>Stock:</strong>{" "}
                                  {objeto?.producto?.stock1}
                                </Typography>
                                <Typography fontWeight={"bold"}>
                                  {"$" +
                                    parseFloat(
                                      objeto?.producto?.precio1
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            </ToggleButton>
                          )}

                          {objeto?.producto?.tipo2 && (
                            <ToggleButton
                              value={1}
                              disabled={objeto?.producto?.stock2 < 1}
                            >
                              <Stack sx={{ padding: "0px 20px 0px 20px" }}>
                                <Typography>
                                  {objeto?.producto?.tipo2}
                                </Typography>
                                <Typography>
                                  <strong>Stock:</strong>{" "}
                                  {objeto?.producto?.stock2}
                                </Typography>
                                <Typography fontWeight={"bold"}>
                                  {"$" +
                                    parseFloat(
                                      objeto?.producto?.precio2
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            </ToggleButton>
                          )}
                          {objeto?.producto?.tipo3 && (
                            <ToggleButton
                              value={2}
                              disabled={objeto?.producto?.stock3 < 1}
                            >
                              <Stack sx={{ padding: "0px 20px 0px 20px" }}>
                                <Typography>
                                  {objeto?.producto?.tipo3}
                                </Typography>
                                <Typography>
                                  <strong>Stock:</strong>{" "}
                                  {objeto?.producto?.stock3}
                                </Typography>
                                <Typography fontWeight={"bold"}>
                                  {"$" +
                                    parseFloat(
                                      objeto?.producto?.precio3
                                    ).toFixed(2)}
                                </Typography>
                              </Stack>
                            </ToggleButton>
                          )}
                        </ToggleButtonGroup>
                      </Box>
                    </>
                  )}
                  <br />
                  <Button
                    disabled={
                      (objeto?.producto?.solicitar?.includes("ESPECIALIDAD") &&
                        !objeto?.especialidad) ||
                      (objeto?.producto?.solicitar?.includes("TEMA") &&
                        !objeto?.tema?.trim()?.length > 0) ||
                      (objeto?.producto?.solicitar?.includes(
                        "TIEMPO ENTREGA"
                      ) &&
                        !objeto?.tiempo?.trim()?.length > 0) ||
                      (objeto?.producto?.tipo?.includes("CON STOCK") &&
                        isNaN(objeto?.eleccion))
                    }
                    onClick={() => {
                      let lis = carrito.filter(
                        (va) =>
                          !(
                            va?.producto?.id === objeto?.producto?.id &&
                            objeto?.producto?.tipo === "CON STOCK"
                          )
                      );

                      lis.push({
                        producto: objeto?.producto,
                        cantidad: 1,
                        especialidad: objeto?.especialidad,
                        tiempo: objeto?.tiempo,
                        tema: objeto?.tema,
                        eleccion: objeto?.eleccion,
                      });

                      //econsole.log(lis);

                      formatear(lis);
                    }}
                    endDecorator={
                      <ShoppingCartIcon sx={{ fontSize: "24px" }} />
                    }
                    variant="solid"
                  >
                    <Typography
                      fontWeight={"bold"}
                      sx={{ textAlign: "center" }}
                    >
                      Añadir al carrito
                    </Typography>
                  </Button>
                </Paper>
              </Grid2>
            </Grid2>
          </Box>
        </>
      )}
    </Box>
  );
};
