import { Box } from "@mui/joy";
import React from "react";
import { centrado } from "../utils/Estilos";
import nosotros from "../assets/banner/nosotrosp.jpg";
import { Grid2, Typography } from "@mui/material";

export const Nosotros = () => {
  return (
    <Box sx={{ ...centrado, width: "100%", flexWrap: "wrap" }}>
      <Box
        sx={{
          backgroundImage: "url(" + nosotros + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          width: "100%",
        }}
      />
      {/* <Typography
        variant="h3"
        color="white"
        fontWeight={"bold"}
        align="center"
        sx={{ width: "100%", marginTop: "-300px" }}
      >
        PUBLIMED{" "}
      </Typography> */}

      <Grid2 container sx={{ width: "80%", ...centrado }} py={2}>
        <Grid2 size={{ xs: 12, md: 6 }} sx={{ padding: "20px" }}>
          <Typography variant="h5" fontWeight={"bold"} color="primary">
            Misión
          </Typography>
          <Typography variant="h6">
            Tenemos como misión divulgar el conocimiento reflexivo y crítico de
            la investigación, a través de publicaciones médicas de alta calidad,
            contribuyendo al desarrollo e innovación científica en el área de
            salud.
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }} sx={{ padding: "20px" }}>
          <Typography variant="h5" fontWeight={"bold"} color="primary">
            Visión
          </Typography>
          <Typography variant="h6">
            Ser el referente nacional e internacional de publicaciones
            científicas en el área de la salud, con altos estándares de calidad
            y excelencia en académica.
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12 }} sx={{ padding: "20px" }}>
          <Typography variant="h5" fontWeight={"bold"} color="primary">
            Objetivos
          </Typography>
          <Typography variant="h6">
            El objetivo de la Empresa Publimed es difundir el conocimiento
            científico, a través de los resultados obtenidos, producto de las
            investigaciones científicas realizadas, que representen una
            contribución actualizada para el desarrollo en el área de la salud.
            Incluye trabajos, productos de investigaciones científicas y
            reflexiones teóricas que por su realce, deben publicarse, y de esta
            manera contribuya al futuro de la salud y a los profesionales de
            todo el mundo.
          </Typography>
        </Grid2>
      </Grid2>
    </Box>
  );
};
