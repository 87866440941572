import { Box, Button } from "@mui/joy";
import Grid from "@mui/material/Grid2";

import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import logo from "./../assets/image/LOGO 4.3.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PublicIcon from "@mui/icons-material/Public";
import { centrado } from "../utils/Estilos";
import { Typography } from "@mui/material";
import payphone from "./../assets/banner/payphone.png";


const TikTokIcon = ({ color = "#000000", size = "20px" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={size}
      height={size}
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const Redes = () => {
  return (
    <Box sx={{ color: "white" }}>
      <Typography variant="h6" textAlign="left" pl={1}>
        <strong>Redes Sociales</strong>
      </Typography>
      <Box>
        <Button
          sx={{ color: "white" }}
          onClick={() => window.open("https://www.instagram.com/publimed2/")}
          startDecorator={<InstagramIcon sx={{ fontSize: "24px" }} />}
          variant="plain"
        >
          <Typography fontWeight={"bold"}>Instagram</Typography>
        </Button>
        <br />
        <Button
          sx={{ color: "white" }}
          onClick={() => window.open("https://www.tiktok.com/@publimedpublimed")}
          startDecorator={<TikTokIcon color={"white"} size={"20px"} />}
          variant="plain"
        >
          <Typography fontWeight={"bold"}>Tiktok</Typography>
        </Button>
        <br />
        <Button
          sx={{ color: "white" }}
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=100093661928867"
            )
          }
          startDecorator={<FacebookIcon sx={{ fontSize: "24px" }} />}
          variant="plain"
        >
          <Typography fontWeight={"bold"}>Facebook</Typography>
        </Button>
      </Box>
    </Box>
  );
};

const Contacto = () => {
  return (
    <Box sx={{ color: "white" }}>
      <Typography variant="h5" textAlign="left" pl={1}>
        <strong>Contacto</strong>
      </Typography>
      <Box>
        <Button
          sx={{ color: "white" }}
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=593993490200&text=Hola%2C%20deseo%20informaci%C3%B3n%20sobre..."
            )
          }
          startDecorator={<WhatsAppIcon sx={{ fontSize: "24px" }} />}
          variant="plain"
        >
          <Typography fontWeight={"bold"}>0993490200</Typography>
        </Button>
        <br />
        <Button
          sx={{ color: "white" }}
          onClick={() => window.open("mailto:ventas@publimed.net")}
          startDecorator={<EmailIcon sx={{ fontSize: "24px" }} />}
          variant="plain"
        >
          <Typography fontWeight={"bold"}>ventas@publimed.net</Typography>
        </Button>
        <br />
        <Button
          sx={{ color: "white" }}
          startDecorator={<PublicIcon sx={{ fontSize: "24px" }} />}
          variant="plain"
        >
          <Typography fontWeight={"bold"}>Ecuador</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export const Footer = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-around"}
      alignItems={"center"}
      sx={{
        padding: "20px 5px 20px 5px",
        background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
      }}
    >
      <Grid container sx={{ width: "100%" }}>
        <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ ...centrado }}>
          <Box>
            <img alt={"logoFooter"} src={logo} height={"100px"} />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ ...centrado }}>
          <Box>
            <img alt={"logoFooter"} src={payphone} height={"100px"} />
          </Box>
        </Grid>
        <Grid size={{ md: 3 }} sx={{ ...centrado }}>
          <Redes />
        </Grid>
        <Grid size={{ xs: 9, md: 0 }} />
        <Grid size={{ md: 3 }} sx={{ ...centrado }}>
          <Contacto />
        </Grid>
      </Grid>
    </Box>
  );
};
