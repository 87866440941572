import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Box,
  Button,
} from "@mui/joy";
import { Grow, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useRef, useState } from "react";
import CountUp from "react-countup";
import { centrado } from "../utils/Estilos";
import { theme2 } from "../theme";
import banner from "../assets/banner/publimed.jpg";
import fondo from "../assets/banner/fondo.jpg";
import { useIntersection } from "../navigation/Show";
import { useNavigate } from "react-router-dom";

const Numeros = () => {
  return (
    <Grid container sx={{ width: "90%", ...centrado }}>
      <Grid size={{ xs: 12, md: 4 }} sx={{ padding: "30px" }}>
        <Paper
          elevation={2}
          sx={{
            padding: "20px",
            minHeight: "250px",
            ...centrado,
            border: 1,
            borderRadius: "25px",
            borderColor: theme2.palette.primary.main,
          }}
        >
          <Box textAlign={"center"} sx={{ width: "100%" }}>
            <Typography variant="h2" fontWeight={"bold"} color="primary" pb={2}>
              <CountUp end={1000} duration={1} suffix="+" />
            </Typography>
            <Typography variant="h5" fontWeight={"bold"}>
              Profesionales
            </Typography>
            <Typography variant="h6">
              Que han participado con nosotros.
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} sx={{ padding: "30px" }}>
        <Paper
          elevation={2}
          sx={{
            padding: "20px",
            minHeight: "250px",
            ...centrado,
            border: 1,
            borderRadius: "25px",
            borderColor: theme2.palette.primary.main,
          }}
        >
          <Box textAlign={"center"} sx={{ width: "100%" }}>
            <Typography variant="h2" fontWeight={"bold"} color="primary" pb={2}>
              <CountUp end={25} duration={1} suffix="+" />
            </Typography>
            <Typography variant="h5" fontWeight={"bold"}>
              Libros Médicos
            </Typography>
            <Typography variant="h6">Publicados por Nosotros</Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }} sx={{ padding: "30px" }}>
        <Paper
          elevation={2}
          sx={{
            padding: "20px",
            minHeight: "250px",
            ...centrado,
            border: 1,
            borderRadius: "25px",
            borderColor: theme2.palette.primary.main,
          }}
        >
          <Box textAlign={"center"}>
            <Typography variant="h2" fontWeight={"bold"} color="primary" pb={2}>
              <CountUp end={70} duration={1} suffix="+" />
            </Typography>
            <Typography variant="h5" fontWeight={"bold"}>
              Artículos Médicos
            </Typography>
            <Typography variant="h6">Publicados por Nosotros</Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export const Principal = () => {
  const navigate = useNavigate();

  const triggerRef = useRef(null);
  const isVisible = useIntersection(triggerRef, "0px");
  const [preguntas, setPreguntas] = useState([]);

  cargar();

  function cargar() {
    const myHeaders = new Headers();
    myHeaders.append("X-Api-Key", process.env.REACT_APP_TOKEN);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://portal.publimed.net/api/v1/CPregunta", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setPreguntas(res.list);
      })
      .catch((error) => console.error(error));
  }

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: "url(" + banner + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          width: "100%",
        }}
      />
      <Box mb={2} sx={{ marginTop: "-120px", ...centrado, width: "100%" }}>
        <Numeros />
      </Box>

      <Box pt={10} pb={2}>
        <Typography variant="h2" fontWeight={"bold"} align="center">
          ¿Qué ofrecemos?
        </Typography>
      </Box>
      <Box mb={2} sx={{ ...centrado, width: "100%" }}>
        <Grid container sx={{ width: "90%", ...centrado }}>
          <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
              padding: "20px",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                textAlign: "center",
                padding: "50px",
                border: 1,
                minHeight: "300px",
                borderRadius: "25px",
                borderColor: theme2.palette.primary.main,
                ...centrado,
              }}
            >
              <Box>
                <Typography variant="h4" fontWeight={"bold"}>
                  Publicaciones Médicas
                </Typography>
                <br />
                <Button size="lg" onClick={() => navigate("/tienda")}>
                  Comprar Ahora
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
              padding: "20px",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                textAlign: "center",
                padding: "50px",
                border: 1,
                minHeight: "300px",
                borderRadius: "25px",
                borderColor: theme2.palette.primary.main,
                width: "100%",
                ...centrado,
              }}
            >
              <Box>
                <Typography variant="h4" fontWeight={"bold"}>
                  Cursos
                </Typography>
                <br />

                <Button size="lg" onClick={() => navigate("/tienda")}>
                  Comprar Ahora
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box
        mb={2}
        mt={2}
        sx={{
          backgroundImage: "url(" + fondo + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "400px",
          ...centrado,
          width: "100%",
        }}
      >
        <Box
          sx={{
            padding: "75px",
            color: "white",
            width: "100%",
            background: "rgba(99, 107, 116, 0.7)",
            minHeight: "400px",
          }}
        >
          <Grid container sx={{ width: "100%" }} ref={triggerRef}>
            <Grow in={isVisible}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="h4" fontWeight={"bold"}>
                  Acerca de Publimed
                </Typography>
                <br />
                <Typography variant="h5">
                  Publimed es una empresa dedicada al mejoramiento del
                  profesional de la salud, dándoles las herramientas necesarias
                  y más convenientes para incrementar sus conocimientos, y estar
                  más capacitados para su entorno laboral. También aportar con
                  información actual sobre enfermedades que acontecen en nuestro
                  país.
                </Typography>
              </Grid>
            </Grow>
          </Grid>
        </Box>
      </Box>
      {preguntas?.length > 0 && (
        <Box
          mt={10}
          mb={2}
          sx={{
            bgcolor: "#C7DFF7",
            padding: "50px",
          }}
        >
          <Typography variant="h4" fontWeight={"bold"} align={"center"}>
            Preguntas Frecuentes
          </Typography>
          <Grid pt={5} container sx={{ width: "100%", ...centrado }}>
            <Grid size={{ xs: 12, md: 10 }} sx={{ padding: "20px" }}>
              <AccordionGroup
                variant="soft"
                size="lg"
                sx={{
                  borderRadius: "10px",
                  border: 1,
                  borderColor: theme2.palette.primary.main,
                }}
              >
                {preguntas.map((val, index) => (
                  <Accordion
                    color="white"
                    key={val?.id}
                    sx={{ color: "black" }}
                    defaultExpanded={index === 0}
                  >
                    <AccordionSummary sx={{ padding: "15px" }}>
                      <Typography variant="h6" fontWeight={"bold"}>
                        {val?.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        wordBreak: "break-word",
                        whiteSpace: "pre-line",
                        bgcolor: "white",
                      }}
                    >
                      <Typography variant="h6">{val?.description}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionGroup>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
